<template>
  <footer>
    <div class="columns">
      <div class="first-column">
        <ul class="switcher">
          <li>
            <img alt="" src="@/assets/i/globe.svg" />
          </li>
          <li>
            <a
              href="#"
              @click.prevent="setLocale('en')"
              v-if="$i18n.locale !== 'en'"
              >EN</a
            ><span v-else style="cursor: not-allowed">EN</span>
          </li>
          <li>|</li>
          <li>
            <a
              href="#"
              @click.prevent="setLocale('ru')"
              v-if="$i18n.locale !== 'ru'"
              >RU</a
            ><span v-else class="disabled">RU</span>
          </li>
        </ul>
      </div>
      <div class="second-column">
        <div class="sections">
          <div class="nav">
            <ul>
              <li :key="index" v-for="(item, index) in items">
                <router-link
                  active-class="active"
                  :to="{
                    name: 'MovieList',
                    params: {
                      section: item.id,
                      collection: item.collections[0].id
                    }
                  }"
                  >{{ item.title }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'Favorites' }"
                  active-class="active"
                  >{{ $t("navigation.favorites") }}</router-link
                >
              </li>

              <li>
                <router-link :to="{ name: 'InfoLoti' }" active-class="active">{{
                  $t("navigation.info_loti")
                }}</router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'InfoLuggage' }"
                  active-class="active"
                  >{{ $t("navigation.info_luggage") }}</router-link
                >
              </li>
              <li>
                <router-link to="/help" class="btn help">{{
                  $t("navigation.help")
                }}</router-link>
              </li>
            </ul>
          </div>
          <div class="info">
            <div class="row">
              <div class="col col-24 col-m-14 col-s-12 col-links">
                <router-link class="icon license" to="/license">{{
                  $t("languages.license")
                }}</router-link>
                <router-link class="icon privacy" to="/privacy">{{
                  $t("languages.privacy")
                }}</router-link>
              </div>
              <div class="col col-24 col-m-10 col-s-12 col-button">
                <router-link
                  class="button"
                  :class="{ disabled: !isFlight || !isAuth }"
                  to="/feedback"
                  >{{ $t("navigation.feedback") }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          <p>
            © Copyright Premier IFE. Technics, 2022<br />
            © Content is copyrighted by respective content owners<br />
            © Entertainment program is copyrighted by AeroGroup llc.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import isFlight from "@/mixins/isFlight";
export default {
  name: "FooterCore",
  props: ["items"],
  mixins: [isFlight],
  methods: {
    ...mapMutations({
      setSize: "SET_SIZE"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    })
  }
};
</script>

<style scoped></style>
